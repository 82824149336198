<template>
<!--详情页·-->
<div>
    <div :class="query.userType==7?'title_div2':'title_div'">{{item_info.name}}</div>
    <div>
      <!--过滤项-->
      <div v-for="(item,index) in filter_data" :key="index">
        <!--开启时间与结束时间-->
        <div v-if="item.filterType==0">
          <div class="every_option">
              <div style="display:flex;">
                <div style="font-size:3.4vw;margin-left:6vw;">开始时间</div>
              </div>
              <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(1)">
                <div style="font-size:3.4vw;margin-left:7vw;transform: scale(0.9);" >{{query.startTime?query.startTime:'点击选择'}}</div>
              </div>
          </div>
          <div  class="every_option">
            <div style="display:flex;">
              <div style="font-size:3.4vw;margin-left:6vw;">结束时间</div>
            </div>
            <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(2)">
              <div style="font-size:3.4vw;margin-left:7vw;transform: scale(0.9);" >{{query.endTime?query.endTime:'点击选择'}}</div>
            </div>
          </div>
        </div>
        <!--输入框-->
        <div v-if="item.filterType==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">{{item.filterName}}</div>
          </div>
          <div style="display:flex;align-items:center;margin-right:9vw;">
            <input type="text" v-model="item.param_value" placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;transform: scale(0.9);">
          </div>
        </div>
        <div v-if="item.filterType==4" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">{{item.filterName}}</div>
          </div>
          <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="openChooseList1(item,index)">
            <div style="font-size:3.4vw;margin-left:7vw; transform: scale(0.8);" >{{item.param_value?item.param_value:'货号选择'}}</div>
          </div>
        </div>
        <!--下拉框-->
        <div v-if="item.filterType==3" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">{{item.filterName}}</div>
          </div>
          <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="openChooseList(item,index)">
            <div style="font-size:3.4vw;margin-left:7vw; transform: scale(0.8);" >{{item.param_value?item.param_value:'供应商选择'}}</div>
          </div>
        </div>
      </div>

        <!-- <div v-if="item_info.dateFilter==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">开始时间</div>
          </div>
          <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(1)">
            <div style="font-size:3.4vw;margin-left:7vw;" >{{query.startTime?query.startTime:'点击选择'}}</div>
          </div>
        </div> -->
        <!---->
        <!-- <div  v-if="item_info.dateFilter==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">结束时间</div>
          </div>
          <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(2)">
            <div style="font-size:3.4vw;margin-left:7vw;" >{{query.endTime?query.endTime:'点击选择'}}</div>
          </div>
        </div> -->
        <!---->
        <!-- <div  v-if="item_info.goodsnoFilter==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">货号</div>
          </div>
          <div style="display:flex;align-items:center;margin-right:9vw;">
              <input type="text" v-model="query.goodsno" placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;">
          </div>
        </div> -->
        <!---->
        <!-- <div v-if="item_info.shopFilter==1"  class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">店铺</div>
          </div>
         <div style="display:flex;align-items:center;margin-right:9vw;">
              <input type="text" v-model="query.shop" placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;">
          </div>
        </div> -->
        <!---->
        <!-- <div v-if="item_info.shopTypeFilter==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">商品类别</div>
          </div>
           <div style="display:flex;align-items:center;margin-right:9vw;">
              <input type="text" v-model="query.shopType"  placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;">
          </div>
        </div> -->
        <!---->
        <!-- <div v-if="item_info.dayFilter==1" class="every_option">
          <div style="display:flex;">
            <div style="font-size:3.4vw;margin-left:6vw;">天数</div>
          </div>
           <div style="display:flex;align-items:center;margin-right:9vw;">
              <input type="text" v-model="query.dayFilter"  placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;">
          </div>
        </div> -->
        
        <!--查询与返回-->
        <div style="display:flex;justify-content:center;margin:2vh 0;">
          <van-button type="info"  size="small" @click="data_api(1)">确定查询</van-button>
          <div style="width:6vw;"></div>
          <van-button type="default"  size="small" @click="goBack()">返回上级</van-button>
          <!-- <div style="width:6vw;"></div> -->
          <!-- <van-button type="default"  size="small" @click="daoChu()">导出</van-button> -->
    
        </div>

        <!--查询主体-->
        <div >
          <div style="overflow:scroll" @scroll="scrollFun">
            <!-- 表头 -->
            <div :style="'display:flex;'+(isFixed?'left:-'+scroll_left+'px;':'')" class="box_fixed" id="boxFixed" :class="{'is_fixed' : isFixed}">
              <div style="border-left: 3px solid #ccc; display: flex;align-items: center;justify-content: center;font-size: 2.5vw;min-width: 10vw;background-color: #cccccc;"></div>
              <div v-for="(item,index) in table_data[0]" :key="index"  style="background-color: #cccccc;border: 2px solid rgb(226, 226, 226);    width: 16vw;border: 1px solid #E2E2E2;display: flex;align-items: center;justify-content: center;padding: 1vw;font-size: 2.5vw;" >
                <div  v-if="!hide_1.some(item=>item==index)" class="every_title" :style="index=='门店'?'min-width:40vw;text-align:center;':''" >{{index}}</div>
              </div>
              <!--查看详情填充宽度-->
              <div  v-if="item_info.isItem==1&&index!=table_data.length-1" style="min-width:20vw;background-color: #cccccc;"></div>
            </div>
            <!-- 表体 -->
            <div v-for="(item,index) in table_data" :key="index" class="every_select" :style="index==table_data.length-1?'border-bottom: 1px solid #E2E2E2;;':''">
              <div v-if="index+1<table_data.length" style="min-width:10vw;border: 1px solid #E2E2E2;display:flex;align-items:center;justify-content:center;">
                <div>{{index+1}}</div>
              </div>
              <div v-if="index+1==table_data.length" style="min-width:10vw;border: 1px solid #E2E2E2;height:5vh;display:flex;align-items:center;justify-content:center;">
                <div>总计</div>
              </div>
              <!-- <div v-if="item_info.avg_1?index+2>table_info_data.length:false" style="min-width:7vw;border: 1px solid #E2E2E2;height:5vh;line-height:5vh;text-align:center;">均价</div> -->
              <div v-for="(item2,index2) in item"  :key="index2" style="display:flex;">
                <div  v-if="!hide_1.some(item=>item==index2)" class="every_item2" :style="index2=='门店'?'width:40vw;':''">
                 
                  <el-tooltip placement="top">
                  <div slot="content">{{item2}}</div>
                  <div  style=" overflow:hidden; white-space: nowrap"  >{{item2}}</div>
                 </el-tooltip>
                </div>
              </div>
              <div v-if="item_info.isItem==1&&index!=table_data.length-1" class="look_info" @click="infoBtn(item)">查看详情</div>
              <div v-if="item_info.isItem==1&&index==table_data.length-1" class="look_info"  style="border-right:1px solid #e2e2e2;border-bottom:1px solid #e2e2e2"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="height:3vh;"></div>

    <!--底部弹出-->
    <van-popup v-model="choose_popup" position="bottom" >
      <!--日期筛选栏-->
      <div v-if="popup_type==1">
        <van-datetime-picker v-model="currentDate" type="date" title="选择年月日"  :max-date="maxDate" @confirm="chooseDateBtn" @cancel="cancelBtn"/>
      </div>

      <!--详情子表-->
      <div v-if="popup_type==2" style="height:80vh;overflow:scroll;position:relactive;">
          <div style="position:absolute;right:4vw;top:4vw;font-size:20px;color:#bfbfbf;" @click="choose_popup=false">
            <i class="el-icon-circle-close"></i>
          </div>
          <div style="text-align:center;height:7vh;line-height:7vh;font-size:4vw;font-weight:800;">报表详情</div>
          <div style="min-width:130vw;padding-left:2vw;overflow:scroll;height:70vh;">
            <div style="display:flex;padding-bottom:1vh;">
              <div style="min-width:12vw;"></div>
              <div v-for="(item,index) in table_info_data[0]" :key="index" >
                <div v-if="!hide_2.some(item=>item==index)" style="min-width:20vw;font-size:3.5vw;display:flex;justify-content:center;margin:0 1px;">{{index}}</div>
              </div>
            </div>
            <div v-for="(item,index) in table_info_data" :key="index" class="every_select" style="border-left:1px solid #E2E2E2;">
              <div v-if="index+1<table_info_data.length" style="min-width:12vw;border-top: 1px solid #E2E2E2;border-left: 1px solid #E2E2E2;text-align:center;display:flex;justify-content:center;align-items:center">{{index+1}}</div>
              <div v-if="index+1==table_info_data.length" style="min-width:12vw;border-bottom: 1px solid #E2E2E2;border-top: 1px solid #E2E2E2;height:5.4vh;display:flex;align-items:center;justify-content:center;">
                <div>总计</div>
              </div>
              <!-- <div v-if="item_info.avg_2?index+2>table_info_data.length:false" style="min-width:8vw;border-left: 1px solid #E2E2E2;height:5.4vh;line-height:5.4vh;text-align:center;border:1px solid #E2E2E2;">均价</div> -->
              <div v-for="(item2,index2) in item"  :key="index2" style="display:flex;">
                <div v-if="!hide_2.some(item=>item==index2)" class="every_info" >
                  <div>{{item2}}</div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <!--列表筛选栏-->
      <div v-if="popup_type==3">
        <!--选择器-->
        <div style="display:flex;height:60px;justify-content:center;align-items: center;">
          <div>请选择供应商：</div>
          <el-select v-model="list_search" filterable placeholder="请选择供应商"  v-el-select-loadmore="loadmore" @change="chooseListBtn"  type="small" style="width:50vw">
    <el-option
      v-for="item in show_list"
      :key="item.name"
      :label="item.name"
      :value="item.name">
    </el-option>
  </el-select>
          <!-- <div style="display:flex;">
            <div style="margin-top:25px;margin-left:20px;margin-right:20px;">搜索</div>
            <input type="text" v-model="list_search" placeholder="货号" @input="changeListSearch" style="height:30px;margin-top:20px;border:1px solid #dddddd;width:40vw;">
          </div> -->
          <!-- <div style="display:flex;"> 
            <div style="height:40px;width:60px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="chooseListBtn()">确认</div>
            <div style="height:40px;width:80px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="unChooseListBtn()">取消选择</div>
          </div> -->
        </div>
        <!-- <div style="text-align:center;height:30px;">门店 / code</div> -->
        <!-- <div>
          <div></div>
        </div> -->
        <div style="height:300px;overflow:scroll;display:flex;justify-content:left;margin-top:10px;margin-left:2px;">
          <div>
            <div style="display:flex;">
              <!-- <div v-for="(item,index) in show_list[0]" :key="index"  class="every_popup_option" style="height:40px;">
                {{index}}
              </div> -->
            </div>
            <!-- <div v-for="(item,index) in show_list" :key="index" style="display:flex;" @click="chooseListBtn(item)">
              <div v-for="(item2,index2) in item" :key="index2" class="every_popup_option" style="border:1px solid #dddddd;margin-top:1vw;">
               <div>{{item2}}</div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="block" style="background:red;width:5vw">
                <el-pagination
                
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :pager-count="1"
                  :current-page="currentPage"
                  :page-sizes="[10, 30, 50, 100]"
                  :page-size="currentSize"
                  layout="total, sizes, prev, pager, next"
                  :total="choose_list.length">
                </el-pagination>
              </div> -->
        <!-- <van-picker :swipe-duration="3" :columns="show_list" @change="onConfirmList"  @cancel="choose_popup=false"/> -->
      </div>
      <!-- 货号选择 -->
      <div v-if="popup_type==4">
        <!--选择器-->
        <div style="display:flex;height:60px;justify-content:center;align-items: center;">
          <div>请选择商品 ：</div>
          <el-select v-model="list_search" filterable placeholder="请选择绑定的商品"  v-el-select-loadmore="loadmore" @change="chooseListBtn" type="small" style="width:50vw">
    <el-option
      v-for="item in show_list"
      :key="item.HH"
      :label="item.HH"
      :value="item.HH">
    </el-option>
  </el-select>
          <!-- <div style="display:flex;">
            <div style="margin-top:25px;margin-left:20px;margin-right:20px;">搜索</div>
            <input type="text" v-model="list_search" placeholder="货号" @input="changeListSearch" style="height:30px;margin-top:20px;border:1px solid #dddddd;width:40vw;">
          </div> -->
          <!-- <div style="display:flex;"> 
            <div style="height:40px;width:60px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="chooseListBtn()">确认</div>
            <div style="height:40px;width:80px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="unChooseListBtn()">取消选择</div>
          </div> -->
        </div>
        <!-- <div style="text-align:center;height:30px;">门店 / code</div> -->
        <!-- <div>
          <div></div>
        </div> -->
        <div style="height:300px;overflow:scroll;display:flex;justify-content:left;margin-top:10px;margin-left:2px;">
          <div>
            <div style="display:flex;">
              <!-- <div v-for="(item,index) in show_list[0]" :key="index"  class="every_popup_option" style="height:40px;">
                {{index}}
              </div> -->
            </div>
            <!-- <div v-for="(item,index) in show_list" :key="index" style="display:flex;" @click="chooseListBtn(item)">
              <div v-for="(item2,index2) in item" :key="index2" class="every_popup_option" style="border:1px solid #dddddd;margin-top:1vw;">
               <div>{{item2}}</div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="block" style="background:red;width:5vw">
                <el-pagination
                
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :pager-count="1"
                  :current-page="currentPage"
                  :page-sizes="[10, 30, 50, 100]"
                  :page-size="currentSize"
                  layout="total, sizes, prev, pager, next"
                  :total="choose_list.length">
                </el-pagination>
              </div> -->
        <!-- <van-picker :swipe-duration="3" :columns="show_list" @change="onConfirmList"  @cancel="choose_popup=false"/> -->
      </div>
      <!-- 货号全部搜索 -->
      <div v-if="popup_type==5">
        <!--选择器-->
        <div style="display:flex;height:60px;justify-content:space-between;">
          <!-- <el-select v-model="list_search" filterable  remote
    reserve-keyword placeholder="请选择搜索"  v-el-select-loadmore="loadmore" @change="chooseListBtn" >
    <el-option
      v-for="item in show_list"
      :key="item.HH"
      :label="item.HH"
      :value="item.HH">
    </el-option>
  </el-select> -->
          <div style="display:flex;">
            <div style="margin-top:25px;margin-left:20px;margin-right:20px;" @click="changeListSearch">搜索</div>
            <input type="text" v-model="list_search" placeholder="请搜索"  style="height:30px;margin-top:20px;border:1px solid #dddddd;width:55vw;">
          </div>
          <div style="display:flex;"> 
            <!-- <div style="height:40px;width:60px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="chooseListBtn()">确认</div> -->
            <div style="height:40px;width:80px;text-align:center;line-height:40px;margin-top:15px;color:#576b95;" @click="unChooseListBtn()">取消选择</div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- <div style="text-align:center;height:30px;">门店 / code</div> -->
        <!-- <div>
          <div></div>
        </div> -->
        <div style="height:300px;overflow:scroll;display:flex;justify-content:center;margin-top:1px;margin-left:2px;">
          <div>
            <div style="display:flex;">
              <!-- <div v-for="(item,index) in show_list[0]" :key="index"  class="every_popup_option" style="height:40px;">
                {{index}}
              </div> -->
            </div> 
            <div v-for="(item,index) in sousuoLIst" :key="index" style="display:flex;margin-left:20px;" @click="chooseListBtnHH(item)" >
              <div v-for="(item2,index2) in item" :key="index2" class="every_popup_option" style="border:1px solid #dddddd;margin-top:1vw;">
               <div style="padding:0 5px;">{{index+1}}、{{item2}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="block" style="background:red;width:5vw">
                <el-pagination
                
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :pager-count="1"
                  :current-page="currentPage"
                  :page-sizes="[10, 30, 50, 100]"
                  :page-size="currentSize"
                  layout="total, sizes, prev, pager, next"
                  :total="choose_list.length">
                </el-pagination>
              </div> -->
        <!-- <van-picker :swipe-duration="3" :columns="show_list" @change="onConfirmList"  @cancel="choose_popup=false"/> -->
      </div>
    </van-popup>
</div>

</template>

<script>
import {
  exportMethod
} from '../utils/exportMethod.js'
export default{
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
          * scrollHeight 获取元素内容高度(只读)
          * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
          * clientHeight 读取元素的可见高度(只读)
          * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
          * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
          */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  
  data() {
    return {
      paytypes: [],
          showedPaytypes: [],
          currentPage: 1,
          currentSize: 10,
          currentPaytype: {},
      maxDate: 0,//时间不超过今天
      currentDate: 0,//用户选中的时间
      choose_popup:false,//筛选弹窗
      list_search:'',//选择列表搜索
      choose_list:[],//选择列表
      show_list:[],//展示给用户选择的列表
      popup_type:0,//弹出层 1日期筛选 2子表
      choose_type:0,//弹窗状态 1开始时间 2结束时间
      query:{
        userType:'',
        userId:'',
        sql:'',
        startTime:'',
        endTime:'',
        goodsno:'',
        shop:'',
        shopType:'',
        dayFilter:'',
      },
      item_info:{
        name:'报表详情'
      },//选中队列详情
      table_data:[],//查询主体
      table_info_data:[],//子表
      loading:'',
      hide_1:[],//主表隐藏列
      hide_2:[],//子表隐藏列
      filter_data:[],//过滤项信息
      filter_index:0,//选择的过滤项列
      choose_value:'',//选中的过滤项弹窗内目前的值
      type2shopId:'',//区域时shopid

      type_id:'',//供应商表时的typeid
      flag: false,
      isFixed: false,
      offsetTop:0,
      scroll_left:0,//向右滑动的距离
      second:[],
      sousuoLIst:[]
    };
  },
  
  created(){

    this.init();
  //  console.log(localStorage.getItem("choose_item"))
  window.addEventListener('scroll',this.initHeight);
      // this.$nextTick( () => {
      //   console.log("1111111111111111112222")
      //   this.offsetTop = document.querySelector('#boxFixed').offsetTop;
      //   console.log( this.$refs.scroll_div.getBoundingClientRect().left,')0000')
       
      // })
  },
  mounted(){
 
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
  methods:{
    daoChu(){
    // 导出
    window.open("https://yibao-yk.oss-cn-zhangjiakou.aliyuncs.com/stmt/20220609/ff62eff8ebfb42d5aaaca4592fd63e00.zip", "_self");
    //  let myObj = {
    //    method: 'post',
    //    url: this.$api.exportExcel,
    //    fileName: `采价单报表.xls`,
    //    data: {
     
    //    }
    //  }
    //  exportMethod(myObj)
    },
    jujiao(){
  
        console.log("聚焦了")
      
   
    },
    loadmore() {
      console.log("到底了")
      this.currentPage++;
      // this.getList(this.formData);
      this.second = this.choose_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
      this.show_list=[...this.show_list,...this.second]
    },
     handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.currentSize=val;
            this.setShowedPaytypes();
          },
          handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage=val;
            this.setShowedPaytypes();
          },
    setShowedPaytypes() {
            console.log((this.currentPage-1)*this.currentSize);
            console.log((this.currentPage-1)*this.currentSize+this.currentSize);
            this.show_list = this.choose_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
            console.log( this.showedPaytypes);
          },
    scrollFun(e){
      this.scroll_left = e.target.scrollLeft;
    },
    initHeight () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // var scrollTop = window.pageYOffset

        console.log(this.offsetTop)
        this.isFixed = scrollTop > this.offsetTop + 130 ? true : false;
      },
    onClick(item){
      alert(item)
    },
    //获取区域详情
    getShopByArea(){
      this.$axios.post(this.$api.getShopByArea,{
        area:JSON.parse(localStorage.getItem("user_info")).shopId
      }).then(res=>{
        console.log(res,"区域")
        this.type2shopId = res.data.data.shopId;
        this.filter_api();
      })
    },
    //供应商打开列表选择器
    openChooseList(item,index){
      this.filter_index = index
        this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/info/getSupplier/${this.query.userId}`,
          {}
        )
        .then((res) => {
             this.choose_list = res.data.data;

             if(this.choose_list.length>0&&this.choose_list.length<4000){
              this.show_list = res.data.data;
              this.choose_popup = true;
               this.popup_type = 3;
              this.show_list = this.choose_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
             }
             if(this.choose_list.length>4000){
          this.choose_popup = true;
        this.popup_type = 5;
        
        }
        });
      // this.$axios.post(this.$api.filter3_api,{
      //    userId:this.query.userId,
      //    sql:item.sql
      // }).then(res=>{
      //   console.log(res,"filter3")
      //   this.choose_list = res.data.data;
      //   this.show_list = res.data.data;
      //    console.log(this.show_list[0],"filter3")
      //   this.choose_popup = true;
      //   this.popup_type = 3;
      //   console.log((this.currentPage-1)*this.currentSize+this.currentSize)
      //   console.log((this.currentPage-1)*this.currentSize)
      //   this.show_list = this.show_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
      //   console.log(this.show_list)
      // })
    },
        //打开货号列表
        openChooseList1(item,index){
      this.filter_index = index
      // this.$axios.post(this.$api.filter3_api,{
      //    userId:this.query.userId,
      //    sql:item.sql
      // }).then(res=>{
      //   console.log(res,"filter3")
      //   this.choose_list = res.data.data;
      //   this.show_list = res.data.data;
      //    console.log(this.show_list[0],"filter3")
      //   this.choose_popup = true;
      //   this.popup_type = 3;
      //   console.log((this.currentPage-1)*this.currentSize+this.currentSize)
      //   console.log((this.currentPage-1)*this.currentSize)
      //   this.show_list = this.show_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
      //   console.log(this.show_list)
      // })
      this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/v1/function/getBindingGoodsNo/${this.query.userId}`,
          {}
        )
        .then((res) => {
          this.choose_list = res.data.data;
       
          if(this.choose_list.length>0&&this.choose_list.length<40000)
        {
          this.show_list = res.data.data;
        this.show_list.forEach(ele => {
          ele.HH=`${ele.HH}/${ele.PM}`
           delete ele.PM
        });
         console.log(this.show_list[0],"filter3")
        this.choose_popup = true;
        this.popup_type = 4;
        console.log((this.currentPage-1)*this.currentSize+this.currentSize)
        console.log((this.currentPage-1)*this.currentSize)
        this.show_list = this.choose_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
        console.log(this.show_list)
        }
        if(this.choose_list.length>40000){
          this.choose_list.forEach(ele => {
          ele.HH=`${ele.HH}/${ele.PM}`
           delete ele.PM
        });
          this.choose_popup = true;
        this.popup_type = 5;
        
        }
        });
    },
    //输入框改变事件
    changeListSearch(e){
      //重新筛选
      console.log(this.choose_list,"this.choose_list")
      let new_list = [];
      for(let i=0;i<this.choose_list.length;i++){
        // if(this.choose_list[i].indexOf(this.list_search) !== -1){
	      //   new_list.push(this.choose_list[i]);
        // }
        let have_font = false
        for(let j in this.choose_list[i]){
          if(this.choose_list[i][j].indexOf(this.list_search) !== -1){
            have_font = true
          }
        }
        if(have_font){
          new_list.push(this.choose_list[i])
        }
      }
      // if(new_list.length){
      //   this.choose_value = new_list[0];
      // }else{
      //   this.choose_value = '';
      // }
    
      this.sousuoLIst = new_list;
     console.log("搜索",this.sousuoLIst)
      // this.show_list = this.choose_list.slice((this.currentPage-1)*this.currentSize, (this.currentPage-1)*this.currentSize+this.currentSize);
    },
    onConfirmList(picker, value, index){
      this.choose_value = value;
    },
    //确定选择按钮
    chooseListBtn(e){
      this.list_search=''
      console.log(e,"e0-0-0-0-")
    e ={HH:e}
      this.filter_data[this.filter_index].param_value = e[Object.keys(e)[0]];//第一个属性回显
      console.log(this.filter_data,"aaa")
      this.filter_data.push(',');
      this.filter_data.pop();
      this.choose_popup = false;
    },
    chooseListBtnHH(e){
      console.log(e,"wwwwwweqw")
      this.sousuoLIst=[]
      this.list_search=''
      this.filter_data[this.filter_index].param_value = e[Object.keys(e)[0]];//第一个属性回显
      this.filter_data.push(',');
      this.filter_data.pop();
      this.choose_popup = false;
      console.log(this.filter_data,"90988")
    },
    //取消选择按钮
    unChooseListBtn(){
      this.sousuoLIst=[]
      this.list_search=''
      this.filter_data[this.filter_index].param_value = '';
      this.choose_value = '';
      this.filter_data.push(',');
      this.filter_data.pop();
      this.choose_popup = false;
    },
    //loading
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    //查询功能菜单详情
    functionInfo(id){
      this.$axios.get(this.$api.functionInfo + this.type_id ).then(res=>{
        if(res.data.code===0){
          console.log(res,"0-00-0-")
        }
       
        this.item_info = res.data.data;
        this.item_info.id = res.data.data.id;
        this.item_info.name = res.data.data.name;
        this.query.sql = res.data.data.sql;
        // localStorage.setItem('choose_item',res.data.data);
        
        this.filter_api();
      })
    },
    //获取过滤项
    filter_api(){
      let shop_id = '';
      console.log(this.query.userType,"this.query.userType")
      if(this.query.userType==2||this.query.userType==4||this.query.userType==6||this.query.userType==7){//区域时
      
        shop_id = this.type2shopId;
        console.log(shop_id,"filter_api")
      }else{
      
        shop_id = JSON.parse(localStorage.getItem("user_info")).shopId
      }
      this.$axios.post(this.$api.filter_api,{
        id:this.item_info.id,
        shop_id:shop_id
      }).then(res=>{
        if(res.data.data){
      
          this.filter_data = res.data.data;
          //  console.log( this.filter_data[0].filterType,this.filter_data[0].filterName,"111111111111")
          //  if(this.filter_data[0].filterName=='天数过滤'){
          //   this.filter_data[0].filterType=10
          //  }
          
        }else{
          console.log("55555555555")
          this.filter_data = [];
        }
       
        for(let i=0;i<this.filter_data.length;i++){
          this.filter_data[i].param_value = '';
        }
    
      })
    },

    //初始化
    init(){
      this.query.userType = JSON.parse(localStorage.getItem("user_info")).type;
      this.query.userId = JSON.parse(localStorage.getItem("user_info")).userId;
      console.log(this.query.userId,"用户id")
      //获取当前时间
      let new_time = new Date();
      this.maxDate = new_time;
      this.currentDate = new_time;
      if(this.$route.query.type){//若传type了,则是供应商版本,需要获取sql
        this.type_id = this.$route.query.type;
        console.log(this.type_id,"this.type_id")
        this.functionInfo()
        return false;
      }
       console.log("functionInfo")
      // this.item_info = JSON.parse(localStorage.getItem('choose_item'));
      if(this.query.userType==2||this.query.userType==4||this.query.userType==6){//若为区域账号
        this.getShopByArea();
      }else{
        //获取过滤项
        this.filter_api();
      }
      this.query.sql = this.item_info.sql;
 
    },
    //取消按钮
    cancelBtn(){
      this.choose_popup = false;
    },
    //选择开始时间
    chooseDate(e){
        this.choose_type = e;
        this.choose_popup = true;
        this.popup_type = 1;
    },
    //选择日期
    chooseDateBtn(){
      this.choose_popup = false;
      let choose_time = this.getYYYYMMDD(this.currentDate)
      if(this.choose_type==1){//选中开始时间
        this.query.startTime = choose_time;
      }else{
        this.query.endTime = choose_time;
      }
      this.currentDate = new Date();
    },
    //时间格式化
    getYYYYMMDD(e){
      let nowDate = new Date(e);
			let day = nowDate.getDate();
			let month = nowDate.getMonth() + 1;
			let year = nowDate.getFullYear();
			return  year + '-' + month + '-' + day;
    },
    //查看详情
    infoBtn(item){
      console.log("e1",item)
      let query = '';
      if(item.门店名称){
        query = item.门店名称;
      }else if(item.门店){
        query = item.门店;
      }else if(item.供应商名称){
        query = item.供应商名称;
      }
      //截取掉编号
      if(this.item_info.name=='门店店员销售排名'){
        //总部账号 将号码前缀截掉
        if(this.query.userType==0){
          query = query.substring(6,query.length)
        }
        
      }else if(this.item_info.name=='各门店近效期品种数'||this.item_info.name=='门店近效期品种数' ){
        query = {
          type:item.类别,
          shop:item.门店,
        }
      }
      console.log("e2",item)
      this.data_api(2,query,item);
    },
    //获取详情
    data_api(e,shop,item){
      console.log(e,"eeeeeeee")
      console.log(shop,"shop")
      let new_query = {};
      if(e==1){//查询主表
        console.log(this.filter_data,"00000")
        for(let i=0;i<this.filter_data.length;i++){
          if(this.filter_data[i].filterType==4||this.filter_data[i].filterType==3||this.filter_data[i].filterType==1){
            console.log("tianshu ")
            console.log(this.filter_data[i].param,"this.filter_data[i].param")
            if(this.filter_data[i].param == 'day'){//若不是天数，则模糊搜索
              new_query[this.filter_data[i].param] =  this.filter_data[i].param_value;
            }else{
              new_query[this.filter_data[i].param] =  '%' + this.filter_data[i].param_value + '%';
            }
          }
        }
        new_query.sql = this.query.sql;
        console.log(new_query.sql,"sssssssssssssss")
        // if(this.item_info.shopFilter==1){
        //   new_query.shop = '%'+this.query.shop+'%';
        // }
  
      }else if(e==2){//查询子表
        for(let i=0;i<this.filter_data.length;i++){
          if(this.filter_data[i].filterType==4||this.filter_data[i].filterType==3||this.filter_data[i].filterType==1){
            if(this.filter_data[i].param == 'day'){//若不是天数，则模糊搜索
              new_query[this.filter_data[i].param] =  this.filter_data[i].param_value;
            }else{
              new_query[this.filter_data[i].param] =    this.filter_data[i].param_value ;
            }
          }
        }
        new_query.sql = this.item_info.itemSql;
        new_query.hh =item.货号;
        console.log(this.filter_data,'9098898998000-')
        console.log(new_query,"0909099009091111111111111111111");
        if(this.item_info.name=='各门店近效期品种数'||this.item_info.name=='门店近效期品种数'){
          
          new_query.type = shop.type;
          new_query.shop = shop.shop;
          console.log(new_query.type ,'11111111111111')
          //天数筛选
          for(let i=0;i<this.filter_data.length;i++){
            if(this.filter_data[i].param == 'day'){
              new_query.day =  this.filter_data[i].param_value;
            }
          }
           // new_query.day = shop.;
        }else if(this.item_info.name == '采购入库查询' || this.item_info.name == '商品库存查询（格列安）' || this.item_info.name == '商品库存查询（博爱）'){
              new_query.supplier=shop
        }else{
          new_query.shop = shop;
        }
        // new_query.sql = this.item_info.itemSql+'and a.hh = #{hh}';

      }

      new_query.userId = this.query.userId;
      new_query.userType = this.query.userType;

      let have_time = false; 
      //判断有无时间选择
      for(let i=0;i<this.filter_data.length;i++){
        if(this.filter_data[i].filterType==0){
          have_time = true;
        }
      }
      if(have_time){
        //判断日期
        if(new Date(this.query.startTime).getTime()>new Date(this.query.endTime).getTime()){
          this.$message({
            message: '时间筛选有误',
            type: 'warning'
          });
          return false;
        }
        new_query.startTime = this.query.startTime;
        new_query.endTime = this.query.endTime;
      }
      this.showLoading();
      // if(this.item_info.goodsnoFilter==1){
      //   new_query.goodsno = this.query.goodsno;
      // }
    
      // if(this.item_info.goodsTypeFilter==1){
      //   new_query.shopType = this.query.shopType;
      // }
      // if(this.item_info.dayFilter==1){
      //   new_query.day = this.query.dayFilter;
      // }
      if(this.query.userType==2||this.query.userType==4||this.query.userType==6||this.query.userType==7){//区域查询
        if(e==1){//若为主表
          console.log(this.type2shopId)
          new_query.shopId = this.type2shopId;
        }else{//若为子表
          if(this.query.userType==2||this.query.userType==4||this.query.userType==6||this.query.userType==7){
            new_query.shopId = this.type2shopId;
          } else{
             new_query.shopId = "%%";
          }
          new_query = {...new_query,...shop};
        }
        
      }else{
        new_query.shopId = JSON.parse(localStorage.getItem("user_info")).shopId;
      }
      
      console.log(new_query.shopId,"this.type_id")
      console.log(this.type_id,"this.type_id")
      if(this.type_id==6){
        new_query.flag=1
      }
      console.log(new_query.hh)
      // console.log((new_query.hh).slice(1,7),"new_query")
      
  if(this.item_info.name=="销售查询"){
    console.log(new_query,"else")
    console.log()
    if(e==1){

      if(new_query.hh=="%%"){

      new_query.hh=''
    }else{
      console.log(new_query,"else1")
      if(new_query.hh){
        new_query.hh=`${"%"}${(new_query.hh).slice(1,7)}${"%"}`
      }

    }
    
      this.$axios.post(this.$api.data_api,new_query).then(res=>{
        if(e==1&&(this.item_info.name=='各门店收款汇总表'|| this.item_info.name=='各门店库存品种数')){
          console.log(new_query,"dddddddddddddddd")
          //若查询主表，且 各门店收款汇总表 各门店汇总
          this.tableDataSpecialFilter(res.data.data);
        }else if(e==1){//查询主表
          console.log(res,"uuuuuuuuuuuuuuuuuuuu")
          if(res.data.code!==0){
            this.$message({
              message:res.data.msg,
              type: 'error'
            });
          }else{
            this.tableDataFilter(res.data.data);
          }
          
        }else{//查询子表
          console.log(new_query,"opppppppppppppppppp")
          this.tableDataInfoFilter(res.data.data);
        }
        this.loading.close();
      })
    }else{
      console.log("e!=1")
      new_query.hh=`${"%"}${(new_query.hh).slice(0,7)}${"%"}`
      this.$axios.post(this.$api.data_api,new_query).then(res=>{
        if(e==1&&(this.item_info.name=='各门店收款汇总表'|| this.item_info.name=='各门店库存品种数')){
          console.log(new_query,"dddddddddddddddd")
          //若查询主表，且 各门店收款汇总表 各门店汇总
          this.tableDataSpecialFilter(res.data.data);
        }else if(e==1){//查询主表
          console.log(res,"uuuuuuuuuuuuuuuuuuuu")
          if(res.data.code!==0){
            this.$message({
              message:res.data.msg,
              type: 'error'
            });
          }else{
            this.tableDataFilter(res.data.data);
          }
          
        }else{//查询子表
          console.log(new_query,"opppppppppppppppppp")
          this.tableDataInfoFilter(res.data.data);
        }
        this.loading.close();
      })
    }
  }else{
    this.$axios.post(this.$api.data_api,new_query).then(res=>{
        if(e==1&&(this.item_info.name=='各门店收款汇总表'|| this.item_info.name=='各门店库存品种数')){
          console.log(new_query,"dddddddddddddddd")
          //若查询主表，且 各门店收款汇总表 各门店汇总
          this.tableDataSpecialFilter(res.data.data);
        }else if(e==1){//查询主表
          console.log(res,"uuuuuuuuuuuuuuuuuuuu")
          if(res.data.code!==0){
            this.$message({
              message:res.data.msg,
              type: 'error'
            });
          }else{
            this.tableDataFilter(res.data.data);
          }
          
        }else{//查询子表
          console.log(new_query,"opppppppppppppppppp")
          this.tableDataInfoFilter(res.data.data);
        }
        this.loading.close();
      })
  }
    },
    //子表数据格式化
    tableDataInfoFilter(data){
      this.table_info_data = data;
      //====================计算总价====================
      let total = {}
      for(let i=0;i<this.table_info_data.length;i++){
        for(let key in this.table_info_data[i]){
          //若为数字类型，则计算总价
          if(typeof this.table_info_data[i][key]=='number'){
            if(total[key]){
              total[key] = total[key] + this.table_info_data[i][key];
            }else{
              total[key] = this.table_info_data[i][key];
            }             
          }else{
            total[key] = '';
          }
        }
      }
      //格式化
      for(let key in total){
        if(typeof total[key]=='number'){
          total[key] = total[key].toFixed(2)*1;
        }
      }
      this.table_info_data.push(total);

      //====================计算平均价====================
      let avg_2 = [];
      if(this.item_info.avg_2){
        avg_2 = this.item_info.avg_2.split(',');
      }
      let avg2_list = [];
      let every_avg = {};
      for(let i=0;i<avg_2.length;i++){
        every_avg = {
          dengyu:avg_2[i].substring(0,avg_2[i].lastIndexOf("=")),
          shu1:avg_2[i].substring(avg_2[i].lastIndexOf("=")+1,avg_2[i].lastIndexOf("/")),
          shu2:avg_2[i].substring(avg_2[i].lastIndexOf("/")+1,avg_2[i].length)
        }
        avg2_list.push(every_avg)
      }
      //avg2_list 为子表平均计算公式数组
   
      let average = {}
      for(let key in this.table_info_data[this.table_info_data.length-1]){
        for(let k=0;k<avg2_list.length;k++){
          if(avg2_list[k].dengyu == key){
              average[key] = this.table_info_data[this.table_info_data.length-1][avg2_list[k].shu1]*1/this.table_info_data[this.table_info_data.length-1][avg2_list[k].shu2]*1;
              average[key] = this.toFixedFun(average[key],6)*1
            }else if(!average[key]){
              average[key]='';
            }
        }
      }
      // this.table_info_data.push(average);

      //====================去掉不显示的总价====================
      if(this.item_info.sum_2){
        let sum_2_list = this.item_info.sum_2.split(',');
        //sum_2_list 为子表不显示总价数组
        for(let key in this.table_info_data[this.table_info_data.length-1]){
          if(!sum_2_list.some(item=>item==key)){
            this.table_info_data[this.table_info_data.length-1][key] = average[key];
          }
        }
      }
   
      //====================隐藏列====================
      if(this.item_info.hide_2){
        this.hide_2 = this.item_info.hide_2.split(',');
      }


      //弹窗界面
      this.choose_popup = true;
      this.popup_type = 2;
  console.log(this.table_info_data,"this.table_info_data")
    },
    //特殊表数据格式化
    tableDataSpecialFilter(data){
      // console.log(this.item_info,"0-0-0-0-12121121")

        this.table_data = data;
        console.log(this.table_data,"this.table_data")
        let total = {};
        for(let i=0;i<this.table_data.length;i++){
          for(let key in this.table_data[i]){
            //若为数字类型，则计算总价
            if(typeof this.table_data[i][key]=='number'){
              if(total[key]){
                total[key] = total[key] + this.table_data[i][key];
              }else{
                total[key] = this.table_data[i][key];
              }             
            }else if(this.table_data[i][key]!=null){//不为null(门店名称)
              total[key] = '';
            }
          }
        }
        //格式化
        for(let key in total){
          if(typeof total[key]=='number'){
            total[key] = total[key].toFixed(2)*1;
          }
        }
        //删除不存在总价的列，(所有的number为null)
        for(let i=0;i<this.table_data.length;i++){
          for(let key in this.table_data[i]){
            let haven = false;
            for(let key2 in total){
              if(key==key2){
                haven = true;
              }
            }
            //遍历过没有，就删除改属性 key
            if(haven==false){
              delete this.table_data[i][key]
            }
          }
        }

        //删除操作之后顺序乱了，对总价进行重新排序
        let total2 ={};
        for(let key in this.table_data[0]){
          for(let key2 in total){
            if(key == key2){
              total2[key] = total[key];
            }
          }
        }
        this.table_data.push(total2);
        console.log(this.table_data,"q1")
    },
    //主表数据格式化
    tableDataFilter(data){
      if(data){
        console.log(data,"333333")
        this.table_data = data;
      }else{
        console.log(data,"aaaa")
        this.table_data = [];
      }
      
      //====================计算总价====================
      let total = {};
      for(let i=0;i<this.table_data.length;i++){
        for(let key in this.table_data[i]){
          //若为数字类型，则计算总价
          if(typeof this.table_data[i][key]=='number'){
            if(total[key]){
              total[key] = total[key] + this.table_data[i][key];
            }else {
              total[key] = this.table_data[i][key];
            }             
          }else if(this.table_data[i][key]!=null){//不为null
            total[key] = '';
          }
        }
      }
      //格式化
      for(let key in total){
        if(typeof total[key]=='number'){
          total[key] = total[key].toFixed(2)*1;
        }
      }
      this.table_data.push(total);
console.log(this.table_data,"this.table_data")
      //====================计算平均价====================
      let avg_1 = [];
      if(this.item_info.avg_1){
        avg_1 = this.item_info.avg_1.split(',');
      }
      let avg1_list = [];
      let every_avg = {};
      for(let i=0;i<avg_1.length;i++){
        every_avg = {
          dengyu:avg_1[i].substring(0,avg_1[i].lastIndexOf("=")),
          shu1:avg_1[i].substring(avg_1[i].lastIndexOf("=")+1,avg_1[i].lastIndexOf("/")),
          shu2:avg_1[i].substring(avg_1[i].lastIndexOf("/")+1,avg_1[i].length)
        }
        avg1_list.push(every_avg)
      }
      //avg1_list 为子表平均计算公式数组
   
      let average = {}
      for(let key in this.table_info_data[this.table_info_data.length-1]){
        for(let k=0;k<avg1_list.length;k++){
          if(avg1_list[k].dengyu == key){
              average[key] = this.table_info_data[this.table_info_data.length-1][avg1_list[k].shu1]*1/this.table_info_data[this.table_info_data.length-1][avg1_list[k].shu2]*1;
              average[key] = this.toFixedFun(average[key],6)*1;
            }else if(!average[key]){
              average[key]='';
            }
        }
      }
      // this.table_info_data.push(average);

      //====================去掉不显示的总价====================
      if(this.item_info.sum_1){
        let sum_1_list = this.item_info.sum_1.split(',');
        //sum_2_list 为子表不显示总价数组
        for(let key in this.table_info_data[this.table_info_data.length-1]){
          if(!sum_1_list.some(item=>item==key)){
            this.table_info_data[this.table_info_data.length-1][key] = average[key];
          }
        }
      }

      //====================隐藏列====================
      if(this.item_info.hide_1){
        this.hide_1 = this.item_info.hide_1.split(',');
      }
      console.log(this.table_data,"this.table_info_data111111")
    },
    //返回上级
    goBack(){
      this.$router.go(-1);
    },
    //不进行四舍五入
    toFixedFun(num,decimal) {
	    num = num.toString();
	    let index = num.indexOf('.');
	    if (index !== -1) {
	    	num = num.substring(0, decimal + index + 1)
	    } else {
	    	num = num.substring(0)
	    }
	    return parseFloat(num).toFixed(decimal)
    }
  }
}
</script>
<style scoped>
body{
  overflow-x: scroll;
}
input{
  border: #FFFFFF;
}
.title_div{
  height:80px;
  background-image: linear-gradient(#04A9F3, #4EC5FB);
  line-height:100px;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;
  
}

.title_div2{
  height:80px;
  background-image: linear-gradient(#3F98EB, #006ED5 );
  line-height:100px;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;
}
 
.every_option{
  height:8vh;

  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}
.every_select{
  display:flex;
  font-size:3.5vw;
  flex-wrap: nowrap;
  /* align-items:center; */
  /* border-top: 1px solid #E2E2E2; */
}
.choose_body{
  position: fixed;
  bottom: 0;
  left: 0;
  height:60vh;
  width: 100vw;
  
}
.look_info{
  min-width:20vw;
  border-top: 2px solid #E2E2E2;
  margin-top:-1px;
  height:5vh;
  line-height:5vh;
  color: #047ff3;
  text-align: center;
}
.every_info{
  width:20vw;
  /* background: red; */
  border:1px solid #E2E2E2;
  min-height:5.4vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.every_item2{
  width:16vw;
  border: 1px solid #E2E2E2;
  display:flex;
  align-items:center;
  justify-content: center;
  padding: 1vw;
  font-size:2.5vw;
}
.every_title{
  min-width:16vw;
  font-size:2.5vw;
  /* padding:0 1vw;
  margin:0 1px; */
  text-align: center;
  transform: scale(0.9);
  
  
}
.every_popup_option{
  /* width:32vw; */
  display:flex;
  justify-content:center;
  align-items:center;
  height:32px;
}
#hove:hover{

 width:80vw;
 background: grey;
}
.box_fixed{
   /* width:; */
  /* width: 810px; */
  /* background: #ccc; */
  
  font-size:2.5vw;
  /* padding:0 1vw;
  margin:0 1px; */
  text-align: center;
  
  }
  .is_fixed{
    position: fixed;
    top: 0;
    /* left: 50%;
    margin-left: -250px;
    z-index: 999; */
  }
</style>